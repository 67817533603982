export const TotalContactsImage = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="53.223" cy="53.2181" r="35.4808" fill="#E6F4F9" />
    <path
      d="M69.2514 31.7568H44.1375C42.1561 31.7568 40.5498 33.3631 40.5498 35.3445V60.4584C40.5498 62.4398 42.1561 64.0461 44.1375 64.0461H69.2514C71.2328 64.0461 72.8391 62.4398 72.8391 60.4584V35.3445C72.8391 33.3631 71.2328 31.7568 69.2514 31.7568Z"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.5171 28.1704V31.7581"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.8755 28.1704V31.7581"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.1142 39.3071H35.0003C33.0189 39.3071 31.4126 40.9134 31.4126 42.8948V68.0087C31.4126 69.9901 33.0189 71.5964 35.0003 71.5964H60.1142C62.0956 71.5964 63.7019 69.9901 63.7019 68.0087V42.8948C63.7019 40.9134 62.0956 39.3071 60.1142 39.3071Z"
      fill="#E6F4F9"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.5283 64.4217C56.5283 63.4702 56.1503 62.5576 55.4775 61.8848C54.8047 61.212 53.8921 60.834 52.9406 60.834H42.1775C41.226 60.834 40.3135 61.212 39.6407 61.8848C38.9678 62.5576 38.5898 63.4702 38.5898 64.4217"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.556 53.6554C49.5374 53.6554 51.1437 52.0491 51.1437 50.0677C51.1437 48.0862 49.5374 46.48 47.556 46.48C45.5745 46.48 43.9683 48.0862 43.9683 50.0677C43.9683 52.0491 45.5745 53.6554 47.556 53.6554Z"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.3853 35.7207V39.3084"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.7354 35.7207V39.3084"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
