import isEqual from 'lodash-es/isEqual'
import isString from 'lodash-es/isString'
import { useEffect } from 'react'
import { Map } from 'immutable'

import { AssetTrackingData } from '@/feature/telemetry'

import { usePrevious } from '@/lib/core/hooks/usePrevious'

import { useTelemetryCredentials } from '@/lib/features/Map/api'

import { useTelemetryStore } from '../store/telemetry'

const sentinel = Symbol('sentinel')

export function useAssetTelemetry(): Map<string, AssetTrackingData>
export function useAssetTelemetry(assetId: string | null | undefined): AssetTrackingData | undefined
export function useAssetTelemetry(
  assetId: unknown = sentinel,
): Map<string, AssetTrackingData> | AssetTrackingData | undefined {
  const { data: credentials } = useTelemetryCredentials()
  const prevCredentials = usePrevious(credentials)

  const socket = useTelemetryStore((state) => state.socket(credentials?.telemetryFleetName))

  const startListeningToSocket = useTelemetryStore((state) => state.startListeningToSocket)
  const closeSocket = useTelemetryStore((state) => state.closeSocket)

  const asset = useTelemetryStore((state) => {
    if (assetId === sentinel) {
      return state.tracking
    } else if (isString(assetId)) {
      return state.tracking.get(assetId)
    }
  })

  useEffect(() => {
    if (!isEqual(credentials, prevCredentials) && socket && credentials?.telemetryToken) {
      closeSocket(credentials.telemetryFleetName)
    }
  }, [credentials, socket])

  useEffect(() => {
    if (!socket && credentials?.telemetryFleetName && credentials?.telemetryToken) {
      return startListeningToSocket(credentials.telemetryFleetName, credentials.telemetryToken)
    }
  }, [credentials, socket])

  return asset
}
