import styled, { DefaultTheme } from 'styled-components'

import { FeatureTypes } from '@/lib/utils/features'

type WrapperProps = {
  $color: FeatureTypes
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex: 0 0 208px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 325px;
  padding: ${({ theme: { layout } }) => `${layout.spacing(3)} ${layout.spacing(4)}`};
  background: ${({ $color, theme }) => theme.colors[$color as keyof DefaultTheme['colors']]};

  @media (max-width: 1125px) {
    flex: 0 0 100%;
    flex-direction: row;
    width: 100%;
    min-height: 0;
    padding: ${({ theme: { layout } }) => `${layout.spacing(2)} ${layout.spacing(4)}`};
  }
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.textOpposite};
  gap: ${({ theme: { layout } }) => layout.spacing(2)};

  @media (max-width: 1125px) {
    gap: ${({ theme: { layout } }) => layout.spacing(1)};
  }
`

export const Title = styled.p`
  margin: 0;
  font-weight: 700;
  line-height: 32px;
`
