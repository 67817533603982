import { useState } from 'react'
import { useSelector } from 'react-redux'

import { FormattedMessage, useIntl } from 'react-intl'
import { FeatureFlag, WhenFlag } from '@/feature/feature-flags'

import { PaginatedList, RequestStatus } from '@/lib/core/interfaces/common'
import { PlusCircleIcon, TruckIcon } from '@/lib/core/components/Icon'
import { routePaths } from '@/lib/core/constants/routes'
import { Button } from '@/lib/core/components/Button'

import {
  AllAssetsImage,
  BridgedFleetImage,
  MyVehiclesImage,
  PTUImage,
} from '@/lib/features/Dashboard/components/images'
import { AddAssetModal } from '@/lib/features/Assets/components/AddAssetModal'
import { StatsBox } from '@/lib/features/Dashboard/components/StatsBox'
import { Details, Row, Summary } from '@/lib/features/Dashboard/Dashboard.styles'
import messages from '@/lib/features/Dashboard/Dashboard.messages'
import {
  Header,
  Title,
  Wrapper,
} from '@/lib/features/Dashboard/components/SummaryCard/SummaryCard.styles'
import { Asset } from '@/lib/features/Assets/interfaces'
import { getSharesSharedToMeSelector } from '@/lib/features/SmartShares/store'

import { FeatureTypes } from '@/lib/utils/features'

export type AssetData<T> = {
  data: PaginatedList<T> | undefined
  isLoading: boolean
}

export type AssetsRowProps = {
  onUpdate: () => void
  allAssets: AssetData<Asset>
  myAssets: AssetData<Asset>
  myVehicles: AssetData<Asset>
  bridgedAssets: AssetData<Asset>
  ptuAssets: AssetData<Asset>
}

export const AssetsRow = ({
  onUpdate,
  allAssets,
  myAssets,
  ptuAssets,
  bridgedAssets,
  myVehicles,
}: AssetsRowProps) => {
  const { formatMessage } = useIntl()
  const [addAssetModalOpen, setAddAssetModalOpen] = useState(false)

  const { data: sharesSharedToMeData, status: sharesSharedToMeStatus } = useSelector(
    getSharesSharedToMeSelector,
  )

  return (
    <>
      <AddAssetModal
        isActive={addAssetModalOpen}
        onClose={(withSuccess) => {
          setAddAssetModalOpen(false)

          if (withSuccess) {
            onUpdate()
          }
        }}
      />
      <Row>
        <Summary>
          {/* <SummaryCard */}
          {/*  callBack={() => setAddAssetModalOpen(true)} */}
          {/*  color={FeatureTypes.ASSETS} */}
          {/*  icon={<TruckIcon size="56" />} */}
          {/*  label={formatMessage(messages.assets)} */}
          {/* /> */}
          <Wrapper $color={FeatureTypes.ASSETS}>
            <Header>
              <TruckIcon size="56" />
              <Title>{formatMessage(messages.assets)}</Title>
            </Header>
            <Button
              type="button"
              fitToContent
              lightVariant
              variant="ghost"
              size="large"
              style={{ visibility: 'hidden' }}
              onClick={() => setAddAssetModalOpen(true)}
              leftIcon={<PlusCircleIcon size="24" />}
            >
              <FormattedMessage {...messages.addNew} />
            </Button>
          </Wrapper>
        </Summary>
        <Details>
          <FeatureFlag flag="featureDashboardChanges">
            <WhenFlag>
              <StatsBox
                href={routePaths.assetsAll}
                color={FeatureTypes.ASSETS}
                counter={allAssets.data?.count ?? 0}
                image={<AllAssetsImage />}
                isLoading={allAssets.isLoading}
                label={formatMessage(messages.allAssets)}
              />
              <StatsBox
                href={routePaths.assetsMyVehicles}
                color={FeatureTypes.ASSETS}
                counter={myAssets.data?.count ?? 0}
                image={<MyVehiclesImage />}
                isLoading={myAssets.isLoading}
                label={'My Assets'}
              />
              <StatsBox
                href={routePaths.assetsShared}
                color={FeatureTypes.ASSETS}
                counter={sharesSharedToMeData.count}
                image={<BridgedFleetImage />}
                isLoading={sharesSharedToMeStatus === RequestStatus.LOADING}
                label={formatMessage(messages.sharedSmartShares)}
              />
            </WhenFlag>
            <WhenFlag not>
              <StatsBox
                href={routePaths.assets}
                color={FeatureTypes.ASSETS}
                counter={allAssets.data?.count ?? 0}
                image={<AllAssetsImage />}
                isLoading={allAssets.isLoading}
                label={formatMessage(messages.allAssets)}
              />
              <StatsBox
                href={routePaths.assetsMyVehicles}
                color={FeatureTypes.ASSETS}
                counter={myVehicles.data?.count ?? 0}
                image={<MyVehiclesImage />}
                isLoading={myVehicles.isLoading}
                label={formatMessage(messages.myVehicles)}
              />
              <StatsBox
                href={routePaths.assetsBridged}
                color={FeatureTypes.ASSETS}
                counter={bridgedAssets.data?.count ?? 0}
                image={<BridgedFleetImage />}
                isLoading={bridgedAssets.isLoading}
                label={formatMessage(messages.bridged)}
              />
              <StatsBox
                href={routePaths.assetsPortableUnits}
                color={FeatureTypes.ASSETS}
                counter={ptuAssets.data?.count ?? 0}
                image={<PTUImage />}
                isLoading={ptuAssets.isLoading}
                label={formatMessage(messages.ptu)}
              />
            </WhenFlag>
          </FeatureFlag>
        </Details>
      </Row>
    </>
  )
}
