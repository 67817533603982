import * as Sentry from '@sentry/nextjs'
import { produce } from 'immer'
import { Map } from 'immutable'
import { debounce, isString } from 'lodash-es'
import get from 'lodash-es/get'
import { finalize } from 'rxjs'
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { z } from 'zod'
import { create } from 'zustand'

import { AssetTrackingData, AssetTrackingDataSchema } from '@/feature/telemetry'

import { getTelemetrySocketUrl } from '../utils'

enum TelemetryStatus {
  UNINITIALIZED,
  CONNECTING,
  IDLE,
  LISTENING,
  ERROR,
}

const socketStatus = z.enum([
  'DISCONNECTED',
  'CONNECTED',
  'AUTH_FAILED',
  'NOT_FOUND',
  'HANDSHAKE',
  'TIMEOUT',
  'TRACKING',
  'CLOSED',
])

type SocketStatus = z.infer<typeof socketStatus>

const statusResolver: Record<SocketStatus, TelemetryStatus> = {
  DISCONNECTED: TelemetryStatus.UNINITIALIZED,
  CONNECTED: TelemetryStatus.IDLE,
  AUTH_FAILED: TelemetryStatus.ERROR,
  NOT_FOUND: TelemetryStatus.ERROR,
  HANDSHAKE: TelemetryStatus.IDLE,
  TIMEOUT: TelemetryStatus.ERROR,
  TRACKING: TelemetryStatus.LISTENING,
  CLOSED: TelemetryStatus.UNINITIALIZED,
}

const statusMapping = {
  'not-found': socketStatus.enum.NOT_FOUND,
  hello: socketStatus.enum.HANDSHAKE,
  timeout: socketStatus.enum.TIMEOUT,
  success: socketStatus.enum.TRACKING,
  'invalid-token': socketStatus.enum.AUTH_FAILED,
} as const

/** This is an optimization to batch the updates to the store */
const collector = {
  items: [] as [AssetTrackingData['sourceId'], AssetTrackingData][],
  processor: undefined as
    | ((assets: { items: [AssetTrackingData['sourceId'], AssetTrackingData][] }) => void)
    | undefined,
  enqueue(item: [AssetTrackingData['sourceId'], AssetTrackingData]) {
    this.items.push(item)

    this.processor?.(this)
  },
}

const messageSchema = z.string().transform((content, ctx) => {
  if (content.startsWith(':')) {
    const [status, msg] = content.slice(1).split(' ', 2)
    if (!(status in statusMapping)) {
      Sentry.captureException(new Error(`(Websocket) Unknown status: ${status}`))
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'unknown status',
      })
      return z.never
    }

    return socketStatus.parse(get(statusMapping, status, msg))
  }
  try {
    content = JSON.parse(content)
  } catch (error) {
    Sentry.captureException(error)
    console.log('Parsing problem', error, content)
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'invalid json',
    })
    return z.never
  }
  try {
    return AssetTrackingDataSchema.parse(content)
  } catch (error) {
    Sentry.captureException(error)
    console.log('Parsing problem', error, content)
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'invalid object',
    })
    if (error instanceof z.ZodError) {
      error.issues.forEach((issue) => {
        ctx.addIssue(issue)
      })
    }
    return z.never
  }
})
// .pipe(parseSchema)

type WebSocketMessage = AssetTrackingData | SocketStatus | string
type WebSocketType = WebSocketSubject<WebSocketMessage>

type TelemetryStore = {
  status: TelemetryStatus
  tracking: Map<string, AssetTrackingData>
  websockets: Record<string, { state: SocketStatus; socket: WebSocketType }>
  ensureSocket(fleet: string, accessToken: string): WebSocketType
  startListeningToSocket(fleet: string, accessToken?: string): () => void
  socket(fleet: string | undefined): WebSocketType | undefined
  closeSocket(fleet: string): void
}

const createSocket = (fleet: string, accessToken: string): WebSocketType =>
  webSocket<WebSocketMessage>({
    url: getTelemetrySocketUrl(fleet),
    deserializer: (e) => messageSchema.parse(e.data) as AssetTrackingData | SocketStatus,
    serializer: (e) => `${e}`,
  })

export const useTelemetryStore = create<TelemetryStore>((setState, getState) => ({
  status: TelemetryStatus.UNINITIALIZED,
  tracking: Map(),
  websockets: {},
  closeSocket(fleet: string) {
    const state = getState()
    if (fleet in state.websockets) {
      setState(
        produce((state) => {
          state.websockets[fleet].state = socketStatus.enum.CLOSED
        }),
      )

      try {
        state.websockets[fleet].socket?.complete?.()
      } catch (error) {
        console.error('Error closing socket', error)
        Sentry.captureException(error)
      }
    }
  },
  ensureSocket(fleet, accessToken) {
    setState(
      produce((state) => {
        if (
          !state.websockets[fleet]?.socket ||
          state.websockets[fleet].state === socketStatus.enum.CLOSED
        ) {
          state.websockets[fleet] = {
            state: socketStatus.enum.DISCONNECTED,
            socket: createSocket(fleet, accessToken),
          }
          state.websockets[fleet].socket.next(accessToken)
        }

        state.status = TelemetryStatus.CONNECTING
      }),
    )

    return getState().websockets[fleet].socket
  },
  startListeningToSocket: debounce(
    (fleet: string, accessToken?: string): (() => void) => {
      const state = getState()

      const socketInfo = state.websockets[fleet]
      let socket = socketInfo?.socket

      collector.processor ||= debounce(
        (bg) => {
          setState(
            produce((state: TelemetryStore) => {
              const assets = bg.items

              bg.items = []

              state.tracking = state.tracking.merge(assets)
            }),
          )
        },
        100,
        {
          leading: false,
          trailing: true,
          maxWait: 500,
        },
      )

      if (
        !socketInfo?.socket ||
        ([socketStatus.enum.DISCONNECTED, socketStatus.enum.CLOSED] as SocketStatus[]).includes(
          socketInfo?.state,
        )
      ) {
        if (!accessToken) {
          throw new Error('Access token is required to create a new socket')
        } else {
          socket = state.ensureSocket(fleet, accessToken)
        }
      }

      const subscription = socket
        .pipe(
          finalize(() => {
            setState(
              produce((state) => {
                state.websockets[fleet].state = socketStatus.enum.DISCONNECTED
                state.websockets[fleet].socket = undefined
              }),
            )
          }),
        )
        .subscribe({
          next: (data: Omit<WebSocketMessage, string>) => {
            if (socketStatus.safeParse(data).success) {
              const socketState = data as SocketStatus

              setState(
                produce((state) => {
                  state.state = statusResolver[socketState]
                  state.websockets[fleet].state = socketState
                }),
              )
            } else if (!isString(data)) {
              const asset = data as AssetTrackingData
              collector.enqueue([asset.sourceId, asset])
            } else {
              console.error('Unknown message', data)
            }
          },
          error: (error) => {
            if (error instanceof CloseEvent) {
              setState(
                produce((state) => {
                  state.websockets[fleet].state = socketStatus.enum.DISCONNECTED
                  state.websockets[fleet].socket = undefined
                }),
              )
            } else {
              console.error('error', error)
              Sentry.captureException(error)
              setState(
                produce((state) => {
                  state.state = TelemetryStatus.ERROR
                  state.websockets[fleet].state = socketStatus.enum.DISCONNECTED
                  state.websockets[fleet].socket = undefined
                }),
              )
            }
            getState().startListeningToSocket(fleet, accessToken)
          },
          complete: () => {
            const state = getState()

            const socketState = state.websockets[fleet].state

            setState(
              produce((state) => {
                state.state = TelemetryStatus.UNINITIALIZED
                state.websockets[fleet].state = socketStatus.enum.CLOSED
                state.websockets[fleet].socket = undefined
              }),
            )

            if (
              ([socketStatus.enum.TIMEOUT, socketStatus.enum.TRACKING] as SocketStatus[]).includes(
                socketState,
              )
            ) {
              state.startListeningToSocket(fleet, accessToken)
            }
          },
        })

      return () => {
        subscription.unsubscribe()
      }
    },
    100,
    { leading: true, trailing: true },
  ),
  socket(fleet: string | undefined): WebSocketType | undefined {
    return fleet == null ? undefined : get(getState().websockets, [fleet, 'socket'])
  },
}))
