import { Asset } from '@/lib/features/Assets/interfaces'

import { suffix } from '@/lib/utils/helpers'

/** @deprecated use asset.vehicleBridgeId instead */
export function getTelemetryAssetSourceId(asset: Asset): string
/** @deprecated use asset?.vehicleBridgeId instead */
export function getTelemetryAssetSourceId(): undefined
/** @deprecated use asset?.vehicleBridgeId instead */
export function getTelemetryAssetSourceId(asset?: null): undefined
/** @deprecated use asset?.vehicleBridgeId instead */
export function getTelemetryAssetSourceId(asset?: Asset | null): string | undefined
/** @deprecated use asset?.vehicleBridgeId instead */
export function getTelemetryAssetSourceId(asset?: Asset | null) {
  return asset?.vehicleBridgeId
}

export const getTelemetrySocketUrl = (fleet: string) => {
  // Currently, only support product geo
  // return `${parseUrl(process.env.NEXT_PUBLIC_BRIDGE_TELEMETRY_WEBSOCKET_URL)}${fleet}/geo`
  return new URL(
    `${fleet}/geo`,
    suffix(process.env.NEXT_PUBLIC_BRIDGE_TELEMETRY_WEBSOCKET_URL),
  ).toString()
}
