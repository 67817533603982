import { FormattedMessage } from 'react-intl'

import { Breadcrumbs } from '@/lib/core/components/Breadcrumbs'
import {
  Location,
  PageHeader,
  PageTitle,
  Section,
} from '@/lib/core/components/Layout/Layout.styles'

import { Dashboard } from '@/lib/features/Dashboard'
import messages from '@/lib/features/Dashboard/Dashboard.messages'

const Page = () => {
  return (
    <>
      <PageHeader>
        <Location>
          <PageTitle>
            <FormattedMessage {...messages.pageTitle} />
          </PageTitle>
          <Breadcrumbs />
        </Location>
      </PageHeader>

      <Section>
        <Dashboard />
      </Section>
    </>
  )
}

export default Page
