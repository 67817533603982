export const PTUImage = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52.0026" cy="51.9987" r="34.6667" fill="#E6F4F9" />
    <path
      d="M36.3909 32.1133L49.1164 44.8389C51.3704 47.0929 51.3704 50.7473 49.1164 53.0012L36.3909 65.7268C34.1369 67.9807 30.4825 67.9807 28.2285 65.7268L15.503 53.0012C13.249 50.7473 13.249 47.0929 15.503 44.8389L28.2285 32.1133C30.4825 29.8594 34.1369 29.8594 36.3909 32.1133Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <circle
      cx="32.3096"
      cy="38.5179"
      r="2.58931"
      transform="rotate(45 32.3096 38.5179)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M27.3047 23.0565C28.6425 21.7452 30.4411 21.0107 32.3144 21.0107C34.1876 21.0107 35.9862 21.7452 37.324 23.0565"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.8096 25.5581C30.4785 24.9024 31.3778 24.5352 32.3144 24.5352C33.251 24.5352 34.1503 24.9024 34.8192 25.5581"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.1592 20.0647C27.1273 18.3043 29.6753 17.3311 32.3159 17.3311C34.9564 17.3311 37.5044 18.3043 39.4725 20.0647"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.9973 41.5332L71.7229 54.2588C73.9768 56.5128 73.9768 60.1672 71.7229 62.4211L58.9973 75.1467C56.7433 77.4007 53.0889 77.4007 50.835 75.1467L38.1094 62.4211C35.8555 60.1672 35.8555 56.5128 38.1094 54.2588L50.835 41.5332C53.0889 39.2793 56.7433 39.2793 58.9973 41.5332Z"
      fill="#E6F4F9"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <circle
      cx="54.916"
      cy="47.9378"
      r="2.58931"
      transform="rotate(45 54.916 47.9378)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M49.9082 32.4765C51.246 31.1652 53.0446 30.4307 54.9179 30.4307C56.7912 30.4307 58.5898 31.1652 59.9276 32.4765"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.4131 34.978C53.082 34.3223 53.9813 33.9551 54.9179 33.9551C55.8546 33.9551 56.7539 34.3223 57.4228 34.978"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.7627 29.4846C49.7309 27.7242 52.2788 26.751 54.9194 26.751C57.5599 26.751 60.1079 27.7242 62.076 29.4846"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
