import { useState } from 'react'

import { useIntl } from 'react-intl'
import { FeatureFlag, WhenFlag } from '@/feature/feature-flags'

import { MergeIcon } from '@/lib/core/components/Icon'
import { routePaths } from '@/lib/core/constants/routes'

import { SummaryCard } from '@/lib/features/Dashboard/components/SummaryCard'
import { StatsBox } from '@/lib/features/Dashboard/components/StatsBox'
import { Details, Row, Summary } from '@/lib/features/Dashboard/Dashboard.styles'
import messages from '@/lib/features/Dashboard/Dashboard.messages'
import {
  SmartSharesAllImage,
  SmartSharesSharedImage,
  SmartSharesSharingImage,
} from '@/lib/features/Dashboard/components/images'
import { AddAssetModal } from '@/lib/features/Assets/components/AddAssetModal'
import { AddContactModal } from '@/lib/features/Contacts/components/AddContactModal'
import { ShareAssetModal } from '@/lib/features/SmartShares/components/ShareAssetModal'
import { useActiveShares } from '@/lib/features/SmartShares/api'
import { ActiveSharesResponse } from '@/lib/features/SmartShares/interfaces'

import { FeatureTypes } from '@/lib/utils/features'

export type SmartSharesRowProps = {
  onUpdate: (features: Array<FeatureTypes>) => void
}

export const SmartSharesRow = ({ onUpdate }: SmartSharesRowProps) => {
  const { formatMessage } = useIntl()
  const [shareAssetModalOpen, setShareAssetModalOpen] = useState(false)
  const [addAssetModalOpen, setAddAssetModalOpen] = useState(false)
  const [addContactModalOpen, setAddContactModalOpen] = useState(false)

  const activeShareResponse = useActiveShares({})
  const activeSharesData = activeShareResponse.data as ActiveSharesResponse
  const isActiveSharesLoading = activeShareResponse.isLoading

  return (
    <>
      <ShareAssetModal
        isActive={shareAssetModalOpen}
        onAddNewAsset={() => {
          setShareAssetModalOpen(false)
          setAddAssetModalOpen(true)
        }}
        onAddNewContact={() => {
          setShareAssetModalOpen(false)
          setAddContactModalOpen(true)
        }}
        onClose={(withSuccess) => {
          setShareAssetModalOpen(false)

          if (withSuccess) {
            onUpdate([FeatureTypes.SHARES, FeatureTypes.CONTACTS])
          }
        }}
      />

      <AddAssetModal
        isActive={addAssetModalOpen}
        onClose={(withSuccess) => {
          setAddAssetModalOpen(false)

          if (withSuccess) {
            onUpdate([FeatureTypes.ASSETS])
          }
        }}
      />

      <AddContactModal
        isActive={addContactModalOpen}
        onClose={(withSuccess) => {
          setAddContactModalOpen(false)

          if (withSuccess) {
            onUpdate([FeatureTypes.CONTACTS])
          }
        }}
      />

      <Row>
        <Summary>
          <SummaryCard
            callBack={() => setShareAssetModalOpen(true)}
            color={FeatureTypes.SHARES}
            icon={<MergeIcon height="56" width="56" />}
            label={formatMessage(messages.smartShares)}
          />
        </Summary>
        <Details>
          <StatsBox
            href={routePaths.shareActiveAll}
            color={FeatureTypes.SHARES}
            counter={activeSharesData?.countAll ?? 0}
            image={<SmartSharesAllImage />}
            isLoading={isActiveSharesLoading}
            label={formatMessage(messages.allShares)}
          />
          <StatsBox
            href={routePaths.shareActiveOutbound}
            color={FeatureTypes.SHARES}
            counter={activeSharesData?.countLent ?? 0}
            image={<SmartSharesSharingImage />}
            isLoading={isActiveSharesLoading}
            label={formatMessage(messages.sharingSmartShares)}
          />
          <StatsBox
            href={routePaths.shareActiveInbound}
            color={FeatureTypes.SHARES}
            counter={activeSharesData?.countBorrowed ?? 0}
            image={<SmartSharesSharedImage />}
            isLoading={isActiveSharesLoading}
            label={formatMessage(messages.sharedSmartShares)}
          />
          <FeatureFlag flag="featureDashboardChanges">
            <WhenFlag>
              <StatsBox
                href={routePaths.shareActiveOnward}
                color={FeatureTypes.SHARES}
                counter={activeSharesData?.countOnward ?? 0}
                image={<SmartSharesAllImage />}
                isLoading={isActiveSharesLoading}
                label={formatMessage(messages.sharingOnwardsSmartShares)}
              />
            </WhenFlag>
          </FeatureFlag>
        </Details>
      </Row>
    </>
  )
}
