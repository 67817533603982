export const MyVehiclesImage = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52.0026" cy="51.9987" r="34.6667" fill="#E6F4F9" />
    <path
      d="M48.75 63.4864H37.9167C34.9251 63.4864 32.5 61.0613 32.5 58.0697L32.5 38.9997C32.5 36.0081 34.9251 33.583 37.9167 33.583L59.3523 33.583C62.3438 33.583 64.769 36.0081 64.769 38.9997V46.583"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M48.75 63.4864H37.9167C34.9251 63.4864 32.5 61.0613 32.5 58.0697L32.5 38.9997C32.5 36.0081 34.9251 33.583 37.9167 33.583L59.3523 33.583C62.3438 33.583 64.769 36.0081 64.769 38.9997V46.583"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M48.75 63.4864H37.9167C34.9251 63.4864 32.5 61.0613 32.5 58.0697L32.5 38.9997C32.5 36.0081 34.9251 33.583 37.9167 33.583L59.3523 33.583C62.3438 33.583 64.769 36.0081 64.769 38.9997V46.583"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 42.0469 57.7461)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 42.0469 57.7461)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 42.0469 57.7461)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M41.1663 63.916V66.9802C41.1663 68.2795 40.1963 69.3327 38.9997 69.3327C37.8031 69.3327 36.833 68.2795 36.833 66.9802L36.833 63.916"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M41.1663 63.916V66.9802C41.1663 68.2795 40.1963 69.3327 38.9997 69.3327C37.8031 69.3327 36.833 68.2795 36.833 66.9802L36.833 63.916"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M41.1663 63.916V66.9802C41.1663 68.2795 40.1963 69.3327 38.9997 69.3327C37.8031 69.3327 36.833 68.2795 36.833 66.9802L36.833 63.916"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M57.4163 75.833V78.8972C57.4163 80.1964 56.4463 81.2497 55.2497 81.2497C54.0531 81.2497 53.083 80.1964 53.083 78.8972L53.083 75.833"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M57.4163 75.833V78.8972C57.4163 80.1964 56.4463 81.2497 55.2497 81.2497C54.0531 81.2497 53.083 80.1964 53.083 78.8972L53.083 75.833"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M57.4163 75.833V78.8972C57.4163 80.1964 56.4463 81.2497 55.2497 81.2497C54.0531 81.2497 53.083 80.1964 53.083 78.8972L53.083 75.833"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M76.9163 75.833V78.8972C76.9163 80.1964 75.9463 81.2497 74.7497 81.2497C73.5531 81.2497 72.583 80.1964 72.583 78.8972L72.583 75.833"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M76.9163 75.833V78.8972C76.9163 80.1964 75.9463 81.2497 74.7497 81.2497C73.5531 81.2497 72.583 80.1964 72.583 78.8972L72.583 75.833"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M76.9163 75.833V78.8972C76.9163 80.1964 75.9463 81.2497 74.7497 81.2497C73.5531 81.2497 72.583 80.1964 72.583 78.8972L72.583 75.833"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="80.2041"
      y="47.3945"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 80.2041 47.3945)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="80.2041"
      y="47.3945"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 80.2041 47.3945)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="80.2041"
      y="47.3945"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 80.2041 47.3945)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M75.5999 75.6709C78.1427 75.6709 80.2041 73.6095 80.2041 71.0667L80.2041 65.0876L49.5601 65.0876L49.5601 71.0667C49.5601 73.6095 51.6215 75.6709 54.1643 75.6709L75.5999 75.6709Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M75.5999 75.6709C78.1427 75.6709 80.2041 73.6095 80.2041 71.0667L80.2041 65.0876L49.5601 65.0876L49.5601 71.0667C49.5601 73.6095 51.6215 75.6709 54.1643 75.6709L75.5999 75.6709Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M75.5999 75.6709C78.1427 75.6709 80.2041 73.6095 80.2041 71.0667L80.2041 65.0876L49.5601 65.0876L49.5601 71.0667C49.5601 73.6095 51.6215 75.6709 54.1643 75.6709L75.5999 75.6709Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 58.292 70.7451)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 58.292 70.7451)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 58.292 70.7451)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 75.918 70.7451)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 75.918 70.7451)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 75.918 70.7451)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path d="M49.8333 50.916H32.5" stroke="#314663" strokeWidth="1.625" />
  </svg>
)
