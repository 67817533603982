import { defineMessages } from 'react-intl'

export default defineMessages({
  addNew: {
    defaultMessage: 'Add New',
    description: 'dashboard | add new button label',
  },
  allAssets: {
    defaultMessage: 'All Assets',
    description: 'dashboard | assets row | all assets card title',
  },
  allContacts: {
    defaultMessage: 'All Contacts',
    description: 'dashboard | contacts row | all contacts card title',
  },
  allShares: {
    defaultMessage: 'All Smart Shares',
    description: 'dashboard | shares row | all smart shares card title',
  },
  assets: {
    defaultMessage: 'Assets',
    description: 'dashboard | assets row | main card title',
  },
  bridged: {
    defaultMessage: 'Bridged Fleet',
    description: 'dashboard | assets row | bridged fleet card title',
  },
  contacts: {
    defaultMessage: 'Contacts',
    description: 'dashboard | contacts row | main card title',
  },
  contactsShare: {
    defaultMessage: 'Contacts I share Assets with',
    description: 'dashboard | contacts row | contacts I share assets with card title',
  },
  contactsSharing: {
    defaultMessage: 'Contacts sharing with me',
    description: 'dashboard | contacts row | contacts sharing with me card title',
  },
  myVehicles: {
    defaultMessage: 'My Vehicles',
    description: 'dashboard | assets row | my vehicles card title',
  },
  pageTitle: {
    defaultMessage: 'Dashboard',
    description: 'dashboard | page title',
  },
  ptu: {
    defaultMessage: 'PTU',
    description: 'dashboard | assets row | ptu card title',
  },
  sharedSmartShares: {
    defaultMessage: 'Assets shared with me',
    description: 'dashboard | smart shares row | assets shared with me card title',
  },
  sharingSmartShares: {
    defaultMessage: 'Assets I am sharing',
    description: 'dashboard | smart shares row | assets I am sharing card title',
  },
  sharingOnwardsSmartShares: {
    defaultMessage: 'Assets Onward Shared',
    description: 'dashboard | smart shares row | assets onward shared card title',
  },
  smartShares: {
    defaultMessage: 'Smart Shares',
    description: 'dashboard | smart shares row | main card title',
  },
})
