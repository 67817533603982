export const ContactsShareImage = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="53.223" cy="51.7308" r="35.4808" fill="#E6F4F9" />
    <path
      d="M73.357 49.1118L73.357 28.1891L80.1665 28.1891L68.2498 14.4451L56.3332 28.1891L63.1427 28.1891L63.1427 49.1118"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.4281 38.2559H20.3143C18.3328 38.2559 16.7266 39.8621 16.7266 41.8436V66.9574C16.7266 68.9389 18.3328 70.5451 20.3143 70.5451H45.4281C47.4096 70.5451 49.0158 68.9389 49.0158 66.9574V41.8436C49.0158 39.8621 47.4096 38.2559 45.4281 38.2559Z"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.8423 63.3704C41.8423 62.4189 41.4643 61.5064 40.7915 60.8335C40.1186 60.1607 39.2061 59.7827 38.2546 59.7827H27.4915C26.54 59.7827 25.6274 60.1607 24.9546 60.8335C24.2818 61.5064 23.9038 62.4189 23.9038 63.3704"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.8699 52.6041C34.8514 52.6041 36.4576 50.9978 36.4576 49.0164C36.4576 47.035 34.8514 45.4287 32.8699 45.4287C30.8885 45.4287 29.2822 47.035 29.2822 49.0164C29.2822 50.9978 30.8885 52.6041 32.8699 52.6041Z"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.6997 34.668V38.2557"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.0454 34.668V38.2557"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
