import { useEffect } from 'react'

import { useAppDispatch } from '@/lib/core/store/store'

import {
  getContactsSharedFromMe,
  getContactsSharedToMe,
  getMyContacts,
} from '@/lib/features/Contacts/store'
import { getSharesSharedFromMe, getSharesSharedToMe } from '@/lib/features/SmartShares/store'
import { AssetsRow, ContactsRow, SmartSharesRow } from '@/lib/features/Dashboard/components/Rows'
import {
  useAllAssets,
  useBridgedAssets,
  useMyVehicles,
  usePtuAssets,
} from '@/lib/features/Assets/api'

import { FeatureTypes } from '@/lib/utils/features'

import { Wrapper } from './Dashboard.styles'

export const Dashboard = () => {
  const dispatch = useAppDispatch()

  const allAssets = useAllAssets()
  const myAssets = useAllAssets({ excludeBridged: true })
  const myVehicles = useMyVehicles()
  const bridgedAssets = useBridgedAssets()
  const ptuAssets = usePtuAssets()

  const refreshAssets = async () => {
    return await Promise.all([
      allAssets.mutate(),
      myVehicles.mutate(),
      bridgedAssets.mutate(),
      ptuAssets.mutate(),
    ])
  }

  const fetchShares = () => {
    dispatch(getSharesSharedFromMe({}))
    dispatch(getSharesSharedToMe({}))
  }

  const fetchContacts = () => {
    dispatch(getMyContacts({}))
    dispatch(getContactsSharedFromMe({}))
    dispatch(getContactsSharedToMe({}))
  }

  useEffect(() => {
    fetchShares()
    fetchContacts()
  }, [])

  return (
    <Wrapper>
      <AssetsRow
        onUpdate={refreshAssets}
        allAssets={allAssets}
        myVehicles={myVehicles}
        bridgedAssets={bridgedAssets}
        ptuAssets={ptuAssets}
        myAssets={myAssets}
      />
      <SmartSharesRow
        onUpdate={(features) => {
          if (features.includes(FeatureTypes.ASSETS)) {
            refreshAssets()
          }

          if (features.includes(FeatureTypes.SHARES)) {
            fetchShares()
          }

          if (features.includes(FeatureTypes.CONTACTS)) {
            fetchContacts()
          }
        }}
      />
      <ContactsRow onUpdate={fetchContacts} />
    </Wrapper>
  )
}
