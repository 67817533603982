export const AllAssetsImage = () => (
  <svg
    width="208"
    height="104"
    viewBox="0 0 208 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="49.5219" cy="59.6845" r="20.0348" fill="#E6F4F9" />
    <circle cx="160.081" cy="52.8246" r="20.0348" fill="#E6F4F9" />
    <path
      d="M132.901 43.5126C131.815 36.8819 125.79 31.7927 118.54 31.7927C115.813 31.7927 113.192 32.5127 110.919 33.8556C107.461 28.3103 101.235 24.8596 94.3182 24.8596C83.6336 24.8596 74.9407 33.1536 74.9407 43.3479C74.9407 43.4111 74.9407 43.4766 74.9431 43.5397C68.0762 44.6433 62.6682 50.3554 62.6682 57.2141C62.6682 64.8604 69.3488 71.0804 77.3629 71.0804H130.651C138.665 71.0804 145.346 64.8604 145.346 57.2141C145.346 50.2969 139.85 44.5486 132.901 43.5126V43.5126Z"
      fill="#E6F4F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.8456 67.2043L46.8456 50.5361C46.8456 48.5891 45.2673 47.0108 43.3204 47.0108L24.5368 47.0108C22.5898 47.0108 21.0115 48.5891 21.0115 50.5361L21.0115 67.2043C21.0115 69.1513 22.5898 70.7296 24.5368 70.7296L43.3204 70.7296C45.2673 70.7296 46.8456 69.1513 46.8456 67.2043ZM48.3564 50.5361C48.3564 47.7547 46.1017 45.5 43.3204 45.5L24.5368 45.5C21.7554 45.5 19.5007 47.7547 19.5007 50.5361L19.5007 67.2043C19.5007 69.9857 21.7554 72.2404 24.5368 72.2404L43.3204 72.2404C46.1017 72.2404 48.3564 69.9857 48.3564 67.2043L48.3564 50.5361Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.8456 62.8324L46.8456 67.2025C46.8456 69.1494 45.2673 70.7277 43.3204 70.7277L24.5368 70.7277C22.5898 70.7277 21.0115 69.1494 21.0115 67.2025L21.0115 62.8324L46.8456 62.8324ZM48.3564 67.2025C48.3564 69.9838 46.1017 72.2385 43.3204 72.2385L24.5368 72.2385C21.7554 72.2385 19.5007 69.9838 19.5007 67.2025L19.5007 61.3216L48.3564 61.3216L48.3564 67.2025Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3467 66.1508L27.5245 66.1508C27.774 66.1508 27.9762 66.3531 27.9762 66.6026C27.9762 66.8521 27.774 67.0544 27.5245 67.0544L24.3467 67.0544C24.0972 67.0544 23.8949 66.8521 23.8949 66.6026C23.8949 66.3531 24.0972 66.1508 24.3467 66.1508ZM29.4871 66.6026C29.4871 67.6865 28.6084 68.5652 27.5245 68.5652L24.3467 68.5652C23.2628 68.5652 22.3841 67.6865 22.3841 66.6026C22.3841 65.5187 23.2628 64.64 24.3467 64.64L27.5245 64.64C28.6084 64.64 29.4871 65.5187 29.4871 66.6026Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.6618 66.1508L43.2835 66.1508C43.533 66.1508 43.7353 66.3531 43.7353 66.6026C43.7353 66.8521 43.533 67.0544 43.2835 67.0544L39.6618 67.0544C39.4123 67.0544 39.21 66.8521 39.21 66.6026C39.21 66.3531 39.4123 66.1508 39.6618 66.1508ZM45.2461 66.6026C45.2461 67.6865 44.3674 68.5652 43.2835 68.5652L39.6618 68.5652C38.5779 68.5652 37.6992 67.6865 37.6992 66.6026C37.6992 65.5187 38.5779 64.64 39.6618 64.64L43.2835 64.64C44.3674 64.64 45.2461 65.5187 45.2461 66.6026Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2707 74.7178L26.2707 72.7591L23.9651 72.7591L23.9651 74.7178C23.9651 75.3545 24.4812 75.8706 25.1179 75.8706C25.7546 75.8706 26.2707 75.3545 26.2707 74.7178ZM27.7815 71.2483L22.4543 71.2483L22.4543 74.7178C22.4543 76.1889 23.6468 77.3814 25.1179 77.3814C26.589 77.3814 27.7815 76.1889 27.7815 74.7178L27.7815 71.2483Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.9396 74.7178L42.9396 72.7591L40.634 72.7591L40.634 74.7178C40.634 75.3545 41.1502 75.8706 41.7868 75.8706C42.4235 75.8706 42.9396 75.3545 42.9396 74.7178ZM44.4504 71.2483L39.1232 71.2483L39.1232 74.7178C39.1232 76.1889 40.3158 77.3814 41.7868 77.3814C43.2579 77.3814 44.4504 76.1889 44.4504 74.7178L44.4504 71.2483Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.1803 84.5371L90.1803 67.8688C90.1803 65.9219 88.602 64.3436 86.6551 64.3436L67.8715 64.3436C65.9245 64.3436 64.3462 65.9219 64.3462 67.8688L64.3462 84.5371C64.3462 86.484 65.9246 88.0623 67.8715 88.0623L86.6551 88.0623C88.602 88.0623 90.1803 86.484 90.1803 84.5371ZM91.6912 67.8688C91.6912 65.0875 89.4364 62.8328 86.6551 62.8328L67.8715 62.8328C65.0902 62.8328 62.8354 65.0875 62.8354 67.8688L62.8354 84.5371C62.8354 87.3184 65.0902 89.5732 67.8715 89.5732L86.6551 89.5732C89.4364 89.5732 91.6912 87.3184 91.6912 84.5371L91.6912 67.8688Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.1803 80.1652L90.1803 84.5352C90.1803 86.4822 88.602 88.0605 86.6551 88.0605L67.8715 88.0605C65.9246 88.0605 64.3462 86.4822 64.3462 84.5352L64.3462 80.1652L90.1803 80.1652ZM91.6912 84.5352C91.6912 87.3166 89.4364 89.5713 86.6551 89.5713L67.8715 89.5713C65.0901 89.5713 62.8354 87.3166 62.8354 84.5352L62.8354 78.6543L91.6912 78.6543L91.6912 84.5352Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.6814 83.4836L70.8592 83.4836C71.1087 83.4836 71.311 83.6858 71.311 83.9354C71.311 84.1849 71.1087 84.3871 70.8592 84.3871L67.6814 84.3871C67.4319 84.3871 67.2296 84.1849 67.2296 83.9354C67.2296 83.6858 67.4319 83.4836 67.6814 83.4836ZM72.8218 83.9354C72.8218 85.0193 71.9431 85.8979 70.8592 85.8979L67.6814 85.8979C66.5975 85.8979 65.7188 85.0193 65.7188 83.9354C65.7188 82.8514 66.5975 81.9728 67.6814 81.9728L70.8592 81.9728C71.9431 81.9728 72.8218 82.8514 72.8218 83.9354Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.9965 83.4836L86.6182 83.4836C86.8677 83.4836 87.07 83.6858 87.07 83.9354C87.07 84.1849 86.8677 84.3871 86.6182 84.3871L82.9965 84.3871C82.747 84.3871 82.5447 84.1849 82.5447 83.9354C82.5447 83.6858 82.747 83.4836 82.9965 83.4836ZM88.5808 83.9354C88.5808 85.0193 87.7021 85.8979 86.6182 85.8979L82.9965 85.8979C81.9126 85.8979 81.0339 85.0193 81.0339 83.9354C81.0339 82.8514 81.9126 81.9728 82.9965 81.9728L86.6182 81.9728C87.7021 81.9728 88.5808 82.8514 88.5808 83.9354Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.6054 92.0506L69.6054 90.0919L67.2998 90.0919L67.2998 92.0506C67.2998 92.6872 67.8159 93.2034 68.4526 93.2034C69.0893 93.2034 69.6054 92.6872 69.6054 92.0506ZM71.1162 88.5811L65.789 88.5811L65.789 92.0506C65.789 93.5216 66.9815 94.7142 68.4526 94.7142C69.9237 94.7142 71.1162 93.5216 71.1162 92.0506L71.1162 88.5811Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.2743 92.0506L86.2743 90.0919L83.9688 90.0919L83.9688 92.0506C83.9688 92.6872 84.4849 93.2034 85.1215 93.2034C85.7582 93.2034 86.2743 92.6872 86.2743 92.0506ZM87.7852 88.5811L82.4579 88.5811L82.4579 92.0506C82.4579 93.5216 83.6505 94.7142 85.1215 94.7142C86.5926 94.7142 87.7852 93.5216 87.7852 92.0506L87.7852 88.5811Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.18 33.6216L116.18 16.9533C116.18 15.0064 114.602 13.4281 112.655 13.4281L93.8715 13.4281C91.9245 13.4281 90.3462 15.0064 90.3462 16.9533L90.3462 33.6216C90.3462 35.5685 91.9246 37.1468 93.8715 37.1468L112.655 37.1468C114.602 37.1468 116.18 35.5685 116.18 33.6216ZM117.691 16.9533C117.691 14.172 115.436 11.9172 112.655 11.9172L93.8715 11.9172C91.0902 11.9172 88.8354 14.172 88.8354 16.9533L88.8354 33.6216C88.8354 36.4029 91.0902 38.6576 93.8715 38.6576L112.655 38.6576C115.436 38.6576 117.691 36.4029 117.691 33.6216L117.691 16.9533Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.18 29.2496L116.18 33.6197C116.18 35.5666 114.602 37.1449 112.655 37.1449L93.8715 37.1449C91.9246 37.1449 90.3462 35.5666 90.3462 33.6197L90.3462 29.2496L116.18 29.2496ZM117.691 33.6197C117.691 36.401 115.436 38.6558 112.655 38.6558L93.8715 38.6558C91.0901 38.6558 88.8354 36.401 88.8354 33.6197L88.8354 27.7388L117.691 27.7388L117.691 33.6197Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.6814 32.568L96.8592 32.568C97.1087 32.568 97.311 32.7703 97.311 33.0198C97.311 33.2693 97.1087 33.4716 96.8592 33.4716L93.6814 33.4716C93.4319 33.4716 93.2296 33.2693 93.2296 33.0198C93.2296 32.7703 93.4319 32.568 93.6814 32.568ZM98.8218 33.0198C98.8218 34.1037 97.9431 34.9824 96.8592 34.9824L93.6814 34.9824C92.5975 34.9824 91.7188 34.1037 91.7188 33.0198C91.7188 31.9359 92.5975 31.0572 93.6814 31.0572L96.8592 31.0572C97.9431 31.0572 98.8218 31.9359 98.8218 33.0198Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.997 32.568L112.618 32.568C112.868 32.568 113.07 32.7703 113.07 33.0198C113.07 33.2693 112.868 33.4716 112.618 33.4716L108.997 33.4716C108.747 33.4716 108.545 33.2693 108.545 33.0198C108.545 32.7703 108.747 32.568 108.997 32.568ZM114.581 33.0198C114.581 34.1037 113.702 34.9824 112.618 34.9824L108.997 34.9824C107.913 34.9824 107.034 34.1037 107.034 33.0198C107.034 31.9359 107.913 31.0572 108.997 31.0572L112.618 31.0572C113.702 31.0572 114.581 31.9359 114.581 33.0198Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.6054 41.135L95.6054 39.1763L93.2998 39.1763L93.2998 41.135C93.2998 41.7717 93.8159 42.2878 94.4526 42.2878C95.0893 42.2878 95.6054 41.7717 95.6054 41.135ZM97.1162 37.6655L91.789 37.6655L91.789 41.135C91.789 42.6061 92.9815 43.7986 94.4526 43.7986C95.9237 43.7986 97.1162 42.6061 97.1162 41.135L97.1162 37.6655Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.274 41.135L112.274 39.1763L109.969 39.1763L109.969 41.135C109.969 41.7717 110.485 42.2878 111.122 42.2878C111.758 42.2878 112.274 41.7717 112.274 41.135ZM113.785 37.6655L108.458 37.6655L108.458 41.135C108.458 42.6061 109.65 43.7986 111.122 43.7986C112.593 43.7986 113.785 42.6061 113.785 41.135L113.785 37.6655Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.18 83.4543L142.18 66.7861C142.18 64.8391 140.602 63.2608 138.655 63.2608L119.871 63.2608C117.925 63.2608 116.346 64.8391 116.346 66.7861L116.346 83.4543C116.346 85.4013 117.925 86.9796 119.871 86.9796L138.655 86.9796C140.602 86.9796 142.18 85.4013 142.18 83.4543ZM143.691 66.7861C143.691 64.0047 141.436 61.75 138.655 61.75L119.871 61.75C117.09 61.75 114.835 64.0047 114.835 66.7861L114.835 83.4543C114.835 86.2357 117.09 88.4904 119.871 88.4904L138.655 88.4904C141.436 88.4904 143.691 86.2357 143.691 83.4543L143.691 66.7861Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.18 79.0824L142.18 83.4525C142.18 85.3994 140.602 86.9777 138.655 86.9777L119.871 86.9777C117.925 86.9777 116.346 85.3994 116.346 83.4525L116.346 79.0824L142.18 79.0824ZM143.691 83.4525C143.691 86.2338 141.436 88.4885 138.655 88.4885L119.871 88.4885C117.09 88.4885 114.835 86.2338 114.835 83.4525L114.835 77.5716L143.691 77.5716L143.691 83.4525Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.681 82.4008L122.859 82.4008C123.109 82.4008 123.311 82.6031 123.311 82.8526C123.311 83.1021 123.109 83.3044 122.859 83.3044L119.681 83.3044C119.432 83.3044 119.23 83.1021 119.23 82.8526C119.23 82.6031 119.432 82.4008 119.681 82.4008ZM124.822 82.8526C124.822 83.9365 123.943 84.8152 122.859 84.8152L119.681 84.8152C118.598 84.8152 117.719 83.9365 117.719 82.8526C117.719 81.7687 118.598 80.89 119.681 80.89L122.859 80.89C123.943 80.89 124.822 81.7687 124.822 82.8526Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.997 82.4008L138.618 82.4008C138.868 82.4008 139.07 82.6031 139.07 82.8526C139.07 83.1021 138.868 83.3044 138.618 83.3044L134.997 83.3044C134.747 83.3044 134.545 83.1021 134.545 82.8526C134.545 82.6031 134.747 82.4008 134.997 82.4008ZM140.581 82.8526C140.581 83.9365 139.702 84.8152 138.618 84.8152L134.997 84.8152C133.913 84.8152 133.034 83.9365 133.034 82.8526C133.034 81.7687 133.913 80.89 134.997 80.89L138.618 80.89C139.702 80.89 140.581 81.7687 140.581 82.8526Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.605 90.9678L121.605 89.0091L119.3 89.0091L119.3 90.9678C119.3 91.6045 119.816 92.1206 120.453 92.1206C121.089 92.1206 121.605 91.6045 121.605 90.9678ZM123.116 87.4983L117.789 87.4983L117.789 90.9678C117.789 92.4389 118.982 93.6314 120.453 93.6314C121.924 93.6314 123.116 92.4389 123.116 90.9678L123.116 87.4983Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.274 90.9678L138.274 89.0091L135.969 89.0091L135.969 90.9678C135.969 91.6045 136.485 92.1206 137.122 92.1206C137.758 92.1206 138.274 91.6045 138.274 90.9678ZM139.785 87.4983L134.458 87.4983L134.458 90.9678C134.458 92.4389 135.65 93.6314 137.122 93.6314C138.593 93.6314 139.785 92.4389 139.785 90.9678L139.785 87.4983Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.4446 47.413C90.8233 47.5882 90.9882 48.0371 90.8131 48.4158L86.4405 57.8686C86.2653 58.2472 85.8164 58.4122 85.4377 58.237C85.0591 58.0619 84.8941 57.6129 85.0692 57.2343L89.4418 47.7815C89.617 47.4028 90.0659 47.2378 90.4446 47.413Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.4446 47.413C90.8233 47.5882 90.9882 48.0371 90.8131 48.4158L86.4405 57.8686C86.2653 58.2472 85.8164 58.4122 85.4377 58.237C85.0591 58.0619 84.8941 57.6129 85.0692 57.2343L89.4418 47.7815C89.617 47.4028 90.0659 47.2378 90.4446 47.413Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.469 47.413C115.09 47.5882 114.925 48.0371 115.101 48.4158L119.473 57.8686C119.648 58.2472 120.097 58.4122 120.476 58.237C120.855 58.0619 121.019 57.6129 120.844 57.2343L116.472 47.7815C116.297 47.4028 115.848 47.2378 115.469 47.413Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.469 47.413C115.09 47.5882 114.925 48.0371 115.101 48.4158L119.473 57.8686C119.648 58.2472 120.097 58.4122 120.476 58.237C120.855 58.0619 121.019 57.6129 120.844 57.2343L116.472 47.7815C116.297 47.4028 115.848 47.2378 115.469 47.413Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.7458 74.3054C97.7459 74.7226 98.0842 75.0608 98.5014 75.0606L108.917 75.0578C109.334 75.0576 109.672 74.7193 109.672 74.3021C109.672 73.8849 109.333 73.5468 108.916 73.5469L98.501 73.5498C98.0838 73.5499 97.7457 73.8882 97.7458 74.3054Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M192.119 50.8716L180.288 39.0402C178.488 37.2396 175.568 37.2396 173.768 39.0402L161.936 50.8716C160.136 52.6722 160.136 55.5915 161.936 57.3921L173.768 69.2235C175.568 71.0241 178.487 71.0241 180.288 69.2235L192.119 57.3921C193.92 55.5915 193.92 52.6722 192.119 50.8716ZM181.356 37.9719C178.966 35.5813 175.09 35.5813 172.699 37.9719L160.868 49.8033C158.477 52.1939 158.477 56.0698 160.868 58.4604L172.699 70.2918C175.09 72.6824 178.966 72.6824 181.356 70.2918L193.188 58.4604C195.578 56.0698 195.578 52.1939 193.188 49.8033L181.356 37.9719Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.858 45.6285C176.503 46.2736 177.549 46.2736 178.194 45.6285C178.839 44.9834 178.839 43.9374 178.194 43.2923C177.549 42.6471 176.503 42.6471 175.858 43.2923C175.213 43.9374 175.213 44.9834 175.858 45.6285ZM174.79 46.6968C176.025 47.932 178.027 47.932 179.263 46.6968C180.498 45.4617 180.498 43.4591 179.263 42.224C178.027 40.9888 176.025 40.9888 174.79 42.224C173.555 43.4591 173.555 45.4617 174.79 46.6968Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M177.028 28.9398C175.484 28.9398 174.002 29.5451 172.899 30.6259C172.601 30.9179 172.123 30.9131 171.831 30.6152C171.539 30.3172 171.543 29.839 171.841 29.5469C173.226 28.1894 175.088 27.429 177.028 27.429C178.967 27.429 180.829 28.1894 182.214 29.5469C182.512 29.839 182.517 30.3172 182.225 30.6152C181.933 30.9131 181.455 30.9179 181.157 30.6259C180.054 29.5451 178.572 28.9398 177.028 28.9398Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M177.031 32.2215C176.358 32.2215 175.711 32.4854 175.231 32.9566C174.933 33.2486 174.455 33.2438 174.162 32.9459C173.87 32.648 173.875 32.1697 174.173 31.8777C174.936 31.1297 175.962 30.7107 177.031 30.7107C178.099 30.7107 179.125 31.1297 179.888 31.8777C180.186 32.1697 180.191 32.648 179.899 32.9459C179.607 33.2438 179.129 33.2486 178.831 32.9566C178.35 32.4854 177.704 32.2215 177.031 32.2215Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M177.031 25.5206C174.762 25.5206 172.572 26.3569 170.881 27.8698C170.57 28.1479 170.092 28.1213 169.814 27.8103C169.536 27.4993 169.562 27.0218 169.873 26.7437C171.842 24.9831 174.39 24.0098 177.031 24.0098C179.672 24.0098 182.22 24.9831 184.188 26.7437C184.499 27.0218 184.526 27.4993 184.248 27.8103C183.97 28.1213 183.492 28.1479 183.181 27.8698C181.49 26.3569 179.3 25.5206 177.031 25.5206Z"
      fill="#314663"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.69 39.652C69.69 29.2048 76.9739 20.4015 86.8018 17.9109V19.4729C77.8059 21.9249 71.2008 30.0581 71.2008 39.652C71.2008 39.7339 71.201 39.785 71.2026 39.8288L71.252 41.1577L69.9402 41.3753C62.5403 42.6031 56.7694 48.9518 56.7694 56.4863C56.7694 58.3639 57.1254 60.1672 57.7746 61.8357V65.1878C56.1817 62.6471 55.2585 59.6684 55.2585 56.4863C55.2585 48.1595 61.6179 41.2247 69.6929 39.8849C69.69 39.8082 69.69 39.7287 69.69 39.652ZM136.354 40.0887C135.252 33.1399 129.374 27.6993 122.11 27.1756V25.6612C130.116 26.1877 136.628 32.1671 137.847 39.852C146.019 41.1097 152.481 48.0884 152.481 56.4863C152.481 60.5568 150.971 64.2943 148.48 67.2083V64.7011C150.054 62.3207 150.97 59.4991 150.97 56.4863C150.97 48.8869 145.105 42.4977 137.617 41.3452L136.527 41.1775L136.354 40.0887Z"
      fill="#314663"
    />
  </svg>
)
