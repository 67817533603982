import { ReactNode } from 'react'
import NextLink from 'next/link'
import { useTheme } from 'styled-components'

import { Loader } from '@/lib/core/components/Loader'

import {
  Counter,
  InfoBox,
  Inner,
  LoaderWrapper,
  Title,
  Wrapper,
} from '@/lib/features/Dashboard/components/StatsBox/StatsBox.styles'

import { FeatureTypes } from '@/lib/utils/features'

export type StatsBoxProps = {
  href: string
  counter: number
  color: FeatureTypes
  image: ReactNode
  isLoading?: boolean
  label: string
}

export const StatsBox = ({ href, counter, color, image, isLoading, label }: StatsBoxProps) => {
  const { colors } = useTheme()

  return (
    <Wrapper>
      <NextLink href={href}>
        <Inner>
          {image}
          <InfoBox>
            <Counter color={color}>
              {isLoading ? (
                <LoaderWrapper>
                  <Loader color={colors.backgroundContent} />
                </LoaderWrapper>
              ) : (
                counter
              )}
            </Counter>
            <Title>{label}</Title>
          </InfoBox>
        </Inner>
      </NextLink>
    </Wrapper>
  )
}
