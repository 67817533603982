export const SmartSharesAllImage = () => (
  <svg
    width="104"
    height="105"
    viewBox="0 0 104 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52.0026" cy="52.4987" r="34.6667" fill="#E6F4F9" />
    <path
      d="M26 58.5706H15.1667C12.1751 58.5706 9.75 56.1455 9.75 53.154L9.75 34.0839C9.75 31.0924 12.1751 28.6672 15.1667 28.6672L36.6023 28.6672C39.5938 28.6672 42.019 31.0924 42.019 34.0839V41.6672"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M26 58.5706H15.1667C12.1751 58.5706 9.75 56.1455 9.75 53.154L9.75 34.0839C9.75 31.0924 12.1751 28.6672 15.1667 28.6672L36.6023 28.6672C39.5938 28.6672 42.019 31.0924 42.019 34.0839V41.6672"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M26 58.5706H15.1667C12.1751 58.5706 9.75 56.1455 9.75 53.154L9.75 34.0839C9.75 31.0924 12.1751 28.6672 15.1667 28.6672L36.6023 28.6672C39.5938 28.6672 42.019 31.0924 42.019 34.0839V41.6672"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 19.2969 52.8306)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 19.2969 52.8306)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 19.2969 52.8306)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M34.6663 70.9172V73.9815C34.6663 75.2807 33.6963 76.3339 32.4997 76.3339C31.3031 76.3339 30.333 75.2807 30.333 73.9815L30.333 70.9172"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M34.6663 70.9172V73.9815C34.6663 75.2807 33.6963 76.3339 32.4997 76.3339C31.3031 76.3339 30.333 75.2807 30.333 73.9815L30.333 70.9172"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M34.6663 70.9172V73.9815C34.6663 75.2807 33.6963 76.3339 32.4997 76.3339C31.3031 76.3339 30.333 75.2807 30.333 73.9815L30.333 70.9172"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M18.4163 59.0005V62.0647C18.4163 63.3639 17.4463 64.4172 16.2497 64.4172C15.0531 64.4172 14.083 63.3639 14.083 62.0647L14.083 59.0005"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M18.4163 59.0005V62.0647C18.4163 63.3639 17.4463 64.4172 16.2497 64.4172C15.0531 64.4172 14.083 63.3639 14.083 62.0647L14.083 59.0005"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M18.4163 59.0005V62.0647C18.4163 63.3639 17.4463 64.4172 16.2497 64.4172C15.0531 64.4172 14.083 63.3639 14.083 62.0647L14.083 59.0005"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M54.1663 70.9172V73.9815C54.1663 75.2807 53.1963 76.3339 51.9997 76.3339C50.8031 76.3339 49.833 75.2807 49.833 73.9815L49.833 70.9172"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M54.1663 70.9172V73.9815C54.1663 75.2807 53.1963 76.3339 51.9997 76.3339C50.8031 76.3339 49.833 75.2807 49.833 73.9815L49.833 70.9172"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M54.1663 70.9172V73.9815C54.1663 75.2807 53.1963 76.3339 51.9997 76.3339C50.8031 76.3339 49.833 75.2807 49.833 73.9815L49.833 70.9172"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="57.4541"
      y="42.4805"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 57.4541 42.4805)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="57.4541"
      y="42.4805"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 57.4541 42.4805)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="57.4541"
      y="42.4805"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 57.4541 42.4805)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M52.8499 70.7571C55.3927 70.7571 57.4541 68.6957 57.4541 66.1529L57.4541 60.1738L26.8101 60.1738L26.8101 66.1529C26.8101 68.6957 28.8715 70.7571 31.4143 70.7571L52.8499 70.7571Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M52.8499 70.7571C55.3927 70.7571 57.4541 68.6957 57.4541 66.1529L57.4541 60.1738L26.8101 60.1738L26.8101 66.1529C26.8101 68.6957 28.8715 70.7571 31.4143 70.7571L52.8499 70.7571Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M52.8499 70.7571C55.3927 70.7571 57.4541 68.6957 57.4541 66.1529L57.4541 60.1738L26.8101 60.1738L26.8101 66.1529C26.8101 68.6957 28.8715 70.7571 31.4143 70.7571L52.8499 70.7571Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 35.542 65.8313)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 35.542 65.8313)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 35.542 65.8313)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 53.168 65.8313)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 53.168 65.8313)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 53.168 65.8313)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path d="M27.0833 46.0005H9.75" stroke="#314663" strokeWidth="1.625" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.2597 31.5242C83.4681 31.7646 83.5171 32.1045 83.385 32.3939C83.2529 32.6833 82.964 32.869 82.6458 32.869H76.6488V75.8125C76.6488 76.0306 76.5628 76.2287 76.4229 76.3747C76.3568 76.757 76.0235 77.0477 75.6224 77.0477H70.5927L80.7295 88.7389L90.8663 77.0477H85.8366C85.3879 77.0477 85.0241 76.6839 85.0241 76.2352L85.0241 55.3125C85.0241 54.8638 85.3879 54.5 85.8366 54.5C86.2854 54.5 86.6491 54.8638 86.6491 55.3125L86.6491 75.4227H92.6462C92.9643 75.4227 93.2532 75.6084 93.3853 75.8978C93.5174 76.1872 93.4685 76.5271 93.2601 76.7675L81.3434 90.5114C81.1891 90.6894 80.9651 90.7917 80.7295 90.7917C80.4939 90.7917 80.2699 90.6894 80.1156 90.5114L68.199 76.7675C67.9905 76.5271 67.9416 76.1872 68.0737 75.8978C68.2058 75.6084 68.4947 75.4227 68.8128 75.4227L75.0238 75.4227V32.0565C75.0238 31.841 75.1094 31.6343 75.2618 31.4819C75.4142 31.3296 75.6208 31.244 75.8363 31.244H80.866L70.7292 19.5528L60.5924 31.244H65.622C66.0708 31.244 66.4345 31.6077 66.4345 32.0565L66.4345 52.9792C66.4345 53.4279 66.0708 53.7917 65.622 53.7917C65.1733 53.7917 64.8095 53.4279 64.8095 52.9792V32.869H58.8125C58.4944 32.869 58.2055 32.6833 58.0734 32.3939C57.9413 32.1045 57.9902 31.7646 58.1986 31.5242L70.1153 17.7802C70.2696 17.6022 70.4936 17.5 70.7292 17.5C70.9648 17.5 71.1887 17.6022 71.3431 17.7802L83.2597 31.5242Z"
      fill="#314663"
    />
  </svg>
)
