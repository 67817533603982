import fastDeepEqual from 'fast-deep-equal'
import { Options } from 'ky'
import { isEmpty } from 'lodash-es'
import useSWR, { Fetcher, preload, SWRConfiguration } from 'swr'

import { TELEMETRY_URL } from '@/feature/telemetry/util/constants'
import { fetcher } from '@/feature/telemetry/util/fetcher'

import { useTelemetryCredentials } from '@/lib/features/Map/api'

type SWRKey =
  | Parameters<typeof fetcher>[0]
  | (() => Parameters<typeof fetcher>[0])
  | null
  | undefined
  | false

export const useTelemetry = <
  Data,
  Error = unknown,
  SWROptions extends SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>> | undefined =
    | SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>>
    | undefined,
>(
  path: string,
  options?: Options,
  swrOptions?: SWROptions,
) => {
  const { data: credentials } = useTelemetryCredentials()

  return useSWR<Data, Error, SWRKey, SWROptions>(
    isEmpty(credentials?.telemetryToken)
      ? null
      : {
          input: path,
          options: {
            ...options,
            prefixUrl: TELEMETRY_URL,
            headers: {
              Authorization: `Token ${credentials?.telemetryToken}`,
              ...options?.headers,
            },
          },
        },
    fetcher,
    {
      compare: fastDeepEqual,
      ...swrOptions,
    } as SWROptions,
  )
}

export const usePreloadTelemetry = <Data,>(path: string, options?: Options) => {
  const { data: credentials } = useTelemetryCredentials()

  return preload<Data, SWRKey>(
    isEmpty(credentials?.telemetryToken)
      ? null
      : {
          input: path,
          options: {
            ...options,
            prefixUrl: TELEMETRY_URL,
            headers: {
              Authorization: `Token ${credentials?.telemetryToken}`,
              ...options?.headers,
            },
          },
        },
    fetcher,
  )
}
