export const SmartSharesSharedImage = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52.0026" cy="51.9987" r="34.6667" fill="#E6F4F9" />
    <path
      d="M30.8359 59.1534H20.0026C17.0111 59.1534 14.5859 56.7283 14.5859 53.7367L14.5859 34.6667C14.5859 31.6751 17.0111 29.25 20.0026 29.25L41.4382 29.25C44.4298 29.25 46.8549 31.6751 46.8549 34.6667V42.25"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M30.8359 59.1534H20.0026C17.0111 59.1534 14.5859 56.7283 14.5859 53.7367L14.5859 34.6667C14.5859 31.6751 17.0111 29.25 20.0026 29.25L41.4382 29.25C44.4298 29.25 46.8549 31.6751 46.8549 34.6667V42.25"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M30.8359 59.1534H20.0026C17.0111 59.1534 14.5859 56.7283 14.5859 53.7367L14.5859 34.6667C14.5859 31.6751 17.0111 29.25 20.0026 29.25L41.4382 29.25C44.4298 29.25 46.8549 31.6751 46.8549 34.6667V42.25"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 24.1328 53.4131)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 24.1328 53.4131)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 24.1328 53.4131)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M39.5023 71.5V74.5642C39.5023 75.8634 38.5322 76.9167 37.3356 76.9167C36.139 76.9167 35.1689 75.8634 35.1689 74.5642L35.1689 71.5"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M39.5023 71.5V74.5642C39.5023 75.8634 38.5322 76.9167 37.3356 76.9167C36.139 76.9167 35.1689 75.8634 35.1689 74.5642L35.1689 71.5"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M39.5023 71.5V74.5642C39.5023 75.8634 38.5322 76.9167 37.3356 76.9167C36.139 76.9167 35.1689 75.8634 35.1689 74.5642L35.1689 71.5"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M23.2523 59.583V62.6472C23.2523 63.9464 22.2822 64.9997 21.0856 64.9997C19.889 64.9997 18.9189 63.9464 18.9189 62.6472L18.9189 59.583"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M23.2523 59.583V62.6472C23.2523 63.9464 22.2822 64.9997 21.0856 64.9997C19.889 64.9997 18.9189 63.9464 18.9189 62.6472L18.9189 59.583"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M23.2523 59.583V62.6472C23.2523 63.9464 22.2822 64.9997 21.0856 64.9997C19.889 64.9997 18.9189 63.9464 18.9189 62.6472L18.9189 59.583"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M59.0023 71.5V74.5642C59.0023 75.8634 58.0322 76.9167 56.8356 76.9167C55.639 76.9167 54.6689 75.8634 54.6689 74.5642L54.6689 71.5"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M59.0023 71.5V74.5642C59.0023 75.8634 58.0322 76.9167 56.8356 76.9167C55.639 76.9167 54.6689 75.8634 54.6689 74.5642L54.6689 71.5"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M59.0023 71.5V74.5642C59.0023 75.8634 58.0322 76.9167 56.8356 76.9167C55.639 76.9167 54.6689 75.8634 54.6689 74.5642L54.6689 71.5"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="62.292"
      y="43.0625"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 62.292 43.0625)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="62.292"
      y="43.0625"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 62.292 43.0625)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="62.292"
      y="43.0625"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 62.292 43.0625)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M57.6878 71.3389C60.2306 71.3389 62.292 69.2775 62.292 66.7347L62.292 60.7556L31.648 60.7556L31.648 66.7347C31.648 69.2775 33.7094 71.3389 36.2522 71.3389L57.6878 71.3389Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M57.6878 71.3389C60.2306 71.3389 62.292 69.2775 62.292 66.7347L62.292 60.7556L31.648 60.7556L31.648 66.7347C31.648 69.2775 33.7094 71.3389 36.2522 71.3389L57.6878 71.3389Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M57.6878 71.3389C60.2306 71.3389 62.292 69.2775 62.292 66.7347L62.292 60.7556L31.648 60.7556L31.648 66.7347C31.648 69.2775 33.7094 71.3389 36.2522 71.3389L57.6878 71.3389Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 40.3799 66.4131)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 40.3799 66.4131)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 40.3799 66.4131)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 58.0059 66.4131)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 58.0059 66.4131)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 58.0059 66.4131)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path d="M31.9193 46.583H14.5859" stroke="#314663" strokeWidth="1.625" />
    <path
      d="M71.2275 56.6953L71.2275 77.618L64.418 77.618L76.3346 91.362L88.2513 77.618L81.4418 77.618L81.4418 56.6953"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
