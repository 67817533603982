import { z } from 'zod'
import omit from 'lodash-es/omit'

export const AssetTrackingDataReceivedSchema = z
  .object({
    altitude: z.number().default(0),
    'bridge-timestamp': z.coerce.date().optional(),
    'device-timestamp': z.coerce.date(),
    'fleet-name': z.string(),
    heading: z.number().optional().default(0),
    latitude: z.number(),
    longitude: z.number(),
    odometer: z.number().optional().default(0),
    'provider-timestamp': z.coerce.date(),
    speed: z.number().optional().default(0),
    'vehicle-bridge-id': z.string(),
    'vehicle-meta-data': z.any().optional(),
  })
  .strip()

export const AssetTrackingDataSchema = AssetTrackingDataReceivedSchema.transform((data) => {
  return {
    ...omit(data, [
      'bridge-timestamp',
      'fleet-name',
      'device-timestamp',
      'provider-timestamp',
      'vehicle-bridge-id',
    ]),
    fleetName: data['fleet-name'],
    sensorDatetime: data['device-timestamp'],
    sourceDatetime: data['provider-timestamp'],
    sourceId: data['vehicle-bridge-id'],
    latitude: data.latitude,
    longitude: data.longitude,
    heading: data.heading,
    speed: data.speed,
  }
})

export type AssetTrackingData = z.infer<typeof AssetTrackingDataSchema>
