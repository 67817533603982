export const SmartSharesSharingImage = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52.0026" cy="51.9987" r="34.6667" fill="#E6F4F9" />
    <path
      d="M26 58.0704H15.1667C12.1751 58.0704 9.75 55.6453 9.75 52.6537L9.75 33.5837C9.75 30.5921 12.1751 28.167 15.1667 28.167L36.6023 28.167C39.5938 28.167 42.019 30.5921 42.019 33.5837V41.167"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M26 58.0704H15.1667C12.1751 58.0704 9.75 55.6453 9.75 52.6537L9.75 33.5837C9.75 30.5921 12.1751 28.167 15.1667 28.167L36.6023 28.167C39.5938 28.167 42.019 30.5921 42.019 33.5837V41.167"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M26 58.0704H15.1667C12.1751 58.0704 9.75 55.6453 9.75 52.6537L9.75 33.5837C9.75 30.5921 12.1751 28.167 15.1667 28.167L36.6023 28.167C39.5938 28.167 42.019 30.5921 42.019 33.5837V41.167"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 19.2969 52.3301)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 19.2969 52.3301)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 19.2969 52.3301)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M34.6663 70.417V73.4812C34.6663 74.7804 33.6963 75.8337 32.4997 75.8337C31.3031 75.8337 30.333 74.7804 30.333 73.4812L30.333 70.417"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M34.6663 70.417V73.4812C34.6663 74.7804 33.6963 75.8337 32.4997 75.8337C31.3031 75.8337 30.333 74.7804 30.333 73.4812L30.333 70.417"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M34.6663 70.417V73.4812C34.6663 74.7804 33.6963 75.8337 32.4997 75.8337C31.3031 75.8337 30.333 74.7804 30.333 73.4812L30.333 70.417"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M18.4163 58.5V61.5642C18.4163 62.8634 17.4463 63.9167 16.2497 63.9167C15.0531 63.9167 14.083 62.8634 14.083 61.5642L14.083 58.5"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M18.4163 58.5V61.5642C18.4163 62.8634 17.4463 63.9167 16.2497 63.9167C15.0531 63.9167 14.083 62.8634 14.083 61.5642L14.083 58.5"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M18.4163 58.5V61.5642C18.4163 62.8634 17.4463 63.9167 16.2497 63.9167C15.0531 63.9167 14.083 62.8634 14.083 61.5642L14.083 58.5"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M54.1663 70.417V73.4812C54.1663 74.7804 53.1963 75.8337 51.9997 75.8337C50.8031 75.8337 49.833 74.7804 49.833 73.4812L49.833 70.417"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M54.1663 70.417V73.4812C54.1663 74.7804 53.1963 75.8337 51.9997 75.8337C50.8031 75.8337 49.833 74.7804 49.833 73.4812L49.833 70.417"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M54.1663 70.417V73.4812C54.1663 74.7804 53.1963 75.8337 51.9997 75.8337C50.8031 75.8337 49.833 74.7804 49.833 73.4812L49.833 70.417"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="57.4541"
      y="41.9805"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 57.4541 41.9805)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="57.4541"
      y="41.9805"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 57.4541 41.9805)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="57.4541"
      y="41.9805"
      width="28.2784"
      height="30.644"
      rx="4.60417"
      transform="rotate(90 57.4541 41.9805)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M52.8499 70.2568C55.3927 70.2568 57.4541 68.1955 57.4541 65.6527L57.4541 59.6736L26.8101 59.6736L26.8101 65.6527C26.8101 68.1955 28.8715 70.2568 31.4143 70.2568L52.8499 70.2568Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M52.8499 70.2568C55.3927 70.2568 57.4541 68.1955 57.4541 65.6527L57.4541 59.6736L26.8101 59.6736L26.8101 65.6527C26.8101 68.1955 28.8715 70.2568 31.4143 70.2568L52.8499 70.2568Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M52.8499 70.2568C55.3927 70.2568 57.4541 68.1955 57.4541 65.6527L57.4541 59.6736L26.8101 59.6736L26.8101 65.6527C26.8101 68.1955 28.8715 70.2568 31.4143 70.2568L52.8499 70.2568Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 35.542 65.3311)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 35.542 65.3311)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.31813"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 35.542 65.3311)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 53.168 65.3311)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 53.168 65.3311)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.76449"
      height="6.81457"
      rx="1.38224"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 53.168 65.3311)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path d="M27.0833 45.5H9.75" stroke="#314663" strokeWidth="1.625" />
    <path
      d="M73.5987 50.1953L73.5987 29.2726L80.4082 29.2726L68.4915 15.5286L56.5749 29.2726L63.3844 29.2726L63.3844 50.1953"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
