const getTelemetryUrl = () => {
  if (process.env.NEXT_PUBLIC_TELEMETRY_URL) {
    return new URL(process.env.NEXT_PUBLIC_TELEMETRY_URL)
  }
  if (process.env.NEXT_PUBLIC_BRIDGE_TELEMETRY_WEBSOCKET_URL) {
    return new URL(
      `https://${new URL(process.env.NEXT_PUBLIC_BRIDGE_TELEMETRY_WEBSOCKET_URL).host}/`,
    )
  }

  throw new Error('Telemetry URL is not set')
}

export const TELEMETRY_URL: URL = getTelemetryUrl()
