import styled, { DefaultTheme } from 'styled-components'

import { FeatureTypes } from '@/lib/utils/features'

export const Wrapper = styled.div`
  align-self: center;
  border: 1px solid #dddddd;
  width: 248px;
  border-radius: 8px;

  &:hover {
    background: #e6f4f9;
    border: 1px solid #e6f4f9;
    cursor: pointer;
  }

  @media (max-width: 1125px) {
    flex: 1 1 25%;
    align-self: flex-start;
    min-height: 300px;
  }
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { layout } }) => `${layout.spacing(4)} ${layout.spacing(2)}`};

  & > svg,
  & > img {
    display: block;
    max-width: 100%;
  }
`

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  font-weight: 700;
  margin-top: ${({ theme: { layout } }) => `${layout.spacing(1)}`};
`

type CounterProps = {
  color: FeatureTypes
}

export const Counter = styled.div<CounterProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 112px;
  height: 70px;
  border-radius: 50%;
  background: ${({ color, theme }) => theme.colors[color as keyof DefaultTheme['colors']]};
  color: ${({ theme: { colors } }) => colors.textOpposite};
  font-size: ${({ theme: { fontSize } }) => fontSize.h3};
  line-height: 40px;
`

export const Title = styled.p`
  margin: 0;
  font-size: ${({ theme: { fontSize } }) => fontSize.small};
  line-height: 1.5756;
  text-align: center;
`

export const LoaderWrapper = styled.div`
  height: 50%;
`
