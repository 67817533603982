export const ContactsSharingImage = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="53.223" cy="53.2181" r="35.4808" fill="#E6F4F9" />
    <path
      d="M63.1425 56.6953L63.1425 77.618L56.333 77.618L68.2497 91.362L80.1663 77.618L73.3568 77.618L73.3568 56.6953"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.6781 38.2559H20.5643C18.5828 38.2559 16.9766 39.8621 16.9766 41.8436V66.9574C16.9766 68.9389 18.5828 70.5451 20.5643 70.5451H45.6781C47.6596 70.5451 49.2658 68.9389 49.2658 66.9574V41.8436C49.2658 39.8621 47.6596 38.2559 45.6781 38.2559Z"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.0923 63.3704C42.0923 62.4189 41.7143 61.5064 41.0415 60.8335C40.3686 60.1607 39.4561 59.7827 38.5046 59.7827H27.7415C26.79 59.7827 25.8774 60.1607 25.2046 60.8335C24.5318 61.5064 24.1538 62.4189 24.1538 63.3704"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.1199 52.6041C35.1014 52.6041 36.7076 50.9978 36.7076 49.0164C36.7076 47.035 35.1014 45.4287 33.1199 45.4287C31.1385 45.4287 29.5322 47.035 29.5322 49.0164C29.5322 50.9978 31.1385 52.6041 33.1199 52.6041Z"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9468 34.668V38.2557"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.293 34.668V38.2557"
      stroke="#314663"
      strokeWidth="1.47837"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
