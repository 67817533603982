export const BridgedFleetImage = () => (
  <svg
    width="208"
    height="104"
    viewBox="0 0 208 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M141.696 45.6422C140.29 37.0551 132.487 30.4642 123.098 30.4642C119.566 30.4642 116.172 31.3966 113.228 33.1357C108.749 25.9542 100.686 21.4854 91.7291 21.4854C77.8918 21.4854 66.6339 32.2266 66.6339 45.4289C66.6339 45.5107 66.6339 45.5955 66.637 45.6773C57.7439 47.1066 50.7402 54.5041 50.7402 63.3866C50.7402 73.2891 59.392 81.3443 69.7708 81.3443H138.783C149.161 81.3443 157.813 73.2891 157.813 63.3866C157.813 54.4283 150.696 46.9839 141.696 45.6422V45.6422Z"
      fill="#E6F4F9"
    />
    <rect
      x="89.7795"
      y="62.7268"
      width="27.1363"
      height="29.4115"
      rx="4.60417"
      transform="rotate(90 89.7795 62.7268)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M89.7795 85.2589L89.7795 79.746L60.368 79.746L60.368 85.2589C60.368 87.8017 62.4294 89.863 64.9722 89.863L85.1754 89.863C87.7182 89.863 89.7795 87.8017 89.7795 85.2589Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.59685"
      height="6.01477"
      rx="1.29842"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 68.6714 85.0959)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.59685"
      height="6.49225"
      rx="1.29842"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 85.624 85.0959)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M67.6516 90.4216L67.6516 93.3409C67.6516 94.4744 66.7327 95.3933 65.5992 95.3933C64.4657 95.3933 63.5468 94.4744 63.5468 93.3409L63.5468 90.4216L67.6516 90.4216Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M85.5774 90.4216L85.5774 93.3409C85.5774 94.4744 84.6585 95.3933 83.525 95.3933C82.3915 95.3933 81.4726 94.4744 81.4726 93.3409L81.4726 90.4216L85.5774 90.4216Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="149.168"
      y="62.7268"
      width="27.1363"
      height="29.4115"
      rx="4.60417"
      transform="rotate(90 149.168 62.7268)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M149.168 85.2589L149.168 79.746L119.757 79.746L119.757 85.2589C119.757 87.8017 121.818 89.863 124.361 89.863L144.564 89.863C147.107 89.863 149.168 87.8017 149.168 85.2589Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.59685"
      height="6.01477"
      rx="1.29842"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 128.064 85.0959)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.59685"
      height="6.49225"
      rx="1.29842"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 145.017 85.0959)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M127.044 90.4216L127.044 93.3409C127.044 94.4744 126.126 95.3933 124.992 95.3933C123.859 95.3933 122.94 94.4744 122.94 93.3409L122.94 90.4216L127.044 90.4216Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M144.97 90.4216L144.97 93.3409C144.97 94.4744 144.051 95.3933 142.918 95.3933C141.784 95.3933 140.865 94.4744 140.865 93.3409L140.865 90.4216L144.97 90.4216Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M90.5893 46.5784L85.8862 56.7456"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
    />
    <path
      d="M118.19 46.5784L122.893 56.7456"
      stroke="#314663"
      strokeWidth="1.625"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.8056 12.2146C69.77 14.7075 59.0781 26.9983 59.0781 41.7388C59.0781 41.8412 59.0781 41.9474 59.0819 42.0499C48.4557 43.8398 40.0872 53.1041 40.0872 64.2281C40.0872 73.7715 46.2092 81.9449 54.6825 85.2052V83.4545C47.1135 80.2815 41.7122 72.8455 41.7122 64.2281C41.7122 53.9373 49.4679 45.3172 59.3518 43.6523L60.7579 43.4154L60.7058 41.9904C60.7033 41.9228 60.7031 41.8467 60.7031 41.7388C60.7031 27.8985 70.6721 16.3397 83.8056 13.8668V12.2146ZM126.815 24.6244C137.099 24.755 145.617 32.3661 147.163 42.2567L147.346 43.4283L148.517 43.6114C158.52 45.1741 166.401 53.8488 166.401 64.2281C166.401 72.9474 160.871 80.4571 153.162 83.5652V85.3069C161.774 82.1174 168.026 73.8723 168.026 64.2281C168.026 53.0091 159.522 43.6861 148.768 42.0059C147.101 31.3378 137.914 23.1299 126.815 22.9993V24.6244ZM95.8399 85.0924H114.596V86.7174H95.8399V85.0924Z"
      fill="#314663"
    />
    <rect
      x="120.815"
      y="9.4812"
      width="27.1363"
      height="29.4115"
      rx="4.60417"
      transform="rotate(90 120.815 9.4812)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M120.815 32.0133L120.815 26.5004L91.4036 26.5004L91.4036 32.0133C91.4036 34.5561 93.465 36.6174 96.0078 36.6174L116.211 36.6174C118.754 36.6174 120.815 34.5561 120.815 32.0133Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.59685"
      height="6.01477"
      rx="1.29842"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 99.7112 31.8462)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <rect
      x="-0.8125"
      y="-0.8125"
      width="2.59685"
      height="6.49225"
      rx="1.29842"
      transform="matrix(3.95496e-08 -1 -1 -4.83111e-08 116.659 31.8462)"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M98.6873 37.176L98.6873 40.0952C98.6873 41.2288 97.7684 42.1477 96.6348 42.1477C95.5013 42.1477 94.5824 41.2288 94.5824 40.0952L94.5824 37.176L98.6873 37.176Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
    <path
      d="M116.613 37.176L116.613 40.0952C116.613 41.2288 115.694 42.1477 114.561 42.1477C113.427 42.1477 112.508 41.2288 112.508 40.0952L112.508 37.176L116.613 37.176Z"
      stroke="#314663"
      strokeWidth="1.625"
    />
  </svg>
)
