import ky, { Input, Options } from 'ky'
import isString from 'lodash-es/isString'

export const fetcher = async <Data = unknown>(
  req: Input | { input: Input; options?: Options },
): Promise<Data> => {
  if (isString(req) || req instanceof URL || req instanceof Request || !('input' in req)) {
    return ky(req).json<Data>()
  } else {
    const { input, options } = req

    return ky(input, options).json<Data>()
  }
}
