import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useIntl } from 'react-intl'

import { RequestStatus } from '@/lib/core/interfaces/common'
import { CheckCircleIcon } from '@/lib/core/components/Icon'
import { routePaths } from '@/lib/core/constants/routes'

import { SummaryCard } from '@/lib/features/Dashboard/components/SummaryCard'
import { StatsBox } from '@/lib/features/Dashboard/components/StatsBox'
import { Details, Row, Summary } from '@/lib/features/Dashboard/Dashboard.styles'
import messages from '@/lib/features/Dashboard/Dashboard.messages'
import {
  ContactsShareImage,
  ContactsSharingImage,
  TotalContactsImage,
} from '@/lib/features/Dashboard/components/images'
import {
  getContactsSharedFromMeSelector,
  getContactsSharedToMeSelector,
  getMyContactsSelector,
} from '@/lib/features/Contacts/store'
import { AddContactModal } from '@/lib/features/Contacts/components/AddContactModal'

import { FeatureTypes } from '@/lib/utils/features'

export type ContactsRowProps = {
  onUpdate: () => void
}

export const ContactsRow = ({ onUpdate }: ContactsRowProps) => {
  const { formatMessage } = useIntl()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data: myContactsData, status: myContactsStatus } = useSelector(getMyContactsSelector)
  const { data: contactsSharedFromMeData, status: contactsSharedFromMeStatus } = useSelector(
    getContactsSharedFromMeSelector,
  )
  const { data: contactsSharedToMeData, status: contactsSharedToMeStatus } = useSelector(
    getContactsSharedToMeSelector,
  )

  return (
    <>
      <AddContactModal
        isActive={isModalOpen}
        onClose={(withSuccess) => {
          setIsModalOpen(false)

          if (withSuccess) {
            onUpdate()
          }
        }}
      />

      <Row>
        <Summary>
          <SummaryCard
            callBack={() => setIsModalOpen(true)}
            color={FeatureTypes.CONTACTS}
            icon={<CheckCircleIcon size="56" />}
            label={formatMessage(messages.contacts)}
          />
        </Summary>
        <Details>
          <StatsBox
            href={routePaths.contacts}
            color={FeatureTypes.CONTACTS}
            counter={myContactsData.count}
            image={<TotalContactsImage />}
            isLoading={myContactsStatus === RequestStatus.LOADING}
            label={formatMessage(messages.allContacts)}
          />
          <StatsBox
            href={routePaths.contacts}
            color={FeatureTypes.CONTACTS}
            counter={contactsSharedFromMeData.count}
            image={<ContactsShareImage />}
            isLoading={contactsSharedFromMeStatus === RequestStatus.LOADING}
            label={formatMessage(messages.contactsShare)}
          />
          <StatsBox
            href={routePaths.contacts}
            color={FeatureTypes.CONTACTS}
            counter={contactsSharedToMeData.count}
            image={<ContactsSharingImage />}
            isLoading={contactsSharedToMeStatus === RequestStatus.LOADING}
            label={formatMessage(messages.contactsSharing)}
          />
        </Details>
      </Row>
    </>
  )
}
