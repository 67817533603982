import { useAssetTelemetry } from '@/feature/telemetry'

export const AssetTelemetry = () => {

  const at = useAssetTelemetry()
  console.log("asset telemetry", at)

  return (
    <></>
  )
}
