import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: ${({ theme: { layout } }) => layout.spacing(4)};
`

export const Row = styled.div`
  overflow: hidden;
  display: flex;
  align-items: stretch;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: ${({ theme: { colors } }) => colors.backgroundContent};

  @media (max-width: 1125px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

export const Summary = styled.div`
  overflow: hidden;
  display: flex;
`

export const Details = styled.div`
  overflow: hidden;
  display: flex;
  gap: ${({ theme: { layout } }) => `${layout.spacing(2)}`};
  padding: ${({ theme: { layout } }) => `${layout.spacing(2)}`};

  @media (max-width: 1125px) {
    flex-wrap: wrap;
  }
`
