import { ReactNode } from 'react'

import { FormattedMessage } from 'react-intl'

import { Button } from '@/lib/core/components/Button'
import { PlusCircleIcon } from '@/lib/core/components/Icon'

import {
  Header,
  Title,
  Wrapper,
} from '@/lib/features/Dashboard/components/SummaryCard/SummaryCard.styles'
import messages from '@/lib/features/Dashboard/Dashboard.messages'

import { FeatureTypes } from '@/lib/utils/features'

export type SummaryCardProps = {
  buttonLabel?: string
  callBack: () => void
  color: FeatureTypes
  icon: ReactNode
  label: string
}

export const SummaryCard = ({ buttonLabel, callBack, color, icon, label }: SummaryCardProps) => (
  <Wrapper $color={color}>
    <Header>
      {icon}
      <Title>{label}</Title>
    </Header>
    <Button
      type="button"
      fitToContent
      lightVariant
      variant="ghost"
      size="large"
      onClick={callBack}
      leftIcon={<PlusCircleIcon size="24" />}
    >
      {buttonLabel || <FormattedMessage {...messages.addNew} />}
    </Button>
  </Wrapper>
)
